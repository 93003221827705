//storyDetail
import { observer } from 'mobx-react';
import store from '../../store';
import { useEffect, useState } from 'react';
import * as ApiService from '../../api/ApiService';
import { stringify } from 'querystring';
import * as utils from '../../utils/utils';
import '../../res/scss/reply-list.scss';
import { Iuser, IusersPage } from '../../const/types';

type strContentType = {
    content: string;
    image: string;
};

interface IReplyList {
    currentPage: number;
    nextPage: number;
    totalCnt: number;
    list: any[];
}

const ReportReplyList = observer(() => {
    const { adminInfo } = store;

    const [replyList, setReplyList] = useState<IReplyList>({
        currentPage: 1,
        nextPage: 0,
        totalCnt: 0,
        list: [],
    } as IReplyList);
    const [currPage, setCurrPage] = useState(1);
    const [nextPage, setNextPage] = useState(1);
    const [totalCnt, setTotalCnt] = useState(0);
    const setPerPageList = [10, 20, 30, 40, 50, 100];
    const [numPerPage, setNumPerPage] = useState(setPerPageList[1]);

    useEffect(() => {
        getReplyList();
    }, []);

    const getReplyList = async (page = 1, perPage = numPerPage) => {
        const dataInfo = await ApiService.getAllReplyListAdmin(page, perPage);
        setCurrPage(dataInfo.currentPage);
        setNextPage(dataInfo.nextPage);

        setReplyList(dataInfo);
        // if (dataInfo.list.length > 0) {
        //     console.log(dataInfo.list);
        //     setReplyList(dataInfo.list);
        // }
        // setCurrPage(dataInfo.currentPage);
        // setTotalCnt(dataInfo.totalCnt);
        // setNextPage(dataInfo.nextPage);
    };

    const movePage = (page: number) => {
        getReplyList(page);
    };

    const setPerPage = (e: any) => {
        const perPage = e.target.value;
        setNumPerPage(perPage);
        getReplyList(1, perPage);
    };

    const goDirectPage = () => {
        const idx = document.getElementById('idx_go_page') as HTMLInputElement;
        const page = parseInt(idx.value);
        if (page > 0 && page <= Math.ceil(totalCnt / numPerPage)) {
            getReplyList(page);
        } else {
            alert(
                '잘못된 페이지 번호입니다.\n마지막 페이지는 ' +
                    Math.ceil(totalCnt / numPerPage) +
                    ' 페이지 입니다.',
            );
            idx.value = '1';
        }
    };

    const replyDelete = async (replyNo = 0, storyNo = 0) => {
        const formData = new FormData();

        // if (strContent.no == 0)
        //     return;

        if (
            window.confirm(
                `댓글번호: ${replyNo}\n스토리번호: ${storyNo}\n\n진짜 삭제 하시겠습니까?`,
            )
        ) {
            const res = await ApiService.deleteReply(replyNo, storyNo);
            if (res) {
                // replyList 에서 제거
                const newList = replyList.list.filter(
                    (reply: any) => reply.reply_no !== replyNo,
                );
                setReplyList({ ...replyList, list: newList });

                utils.showToastMessage('삭제 완료', 1);
                return true;
            }
        } else {
            utils.showToastMessage('삭제가 취소 되었습니다.', 1);
        }
    };

    return (
        <div className="body-content">
            <table className="user-list-top">
                <tr>
                    <td colSpan={8}>
                        <div className="">
                            전체 <b>{totalCnt}</b> 개
                        </div>
                    </td>
                </tr>
            </table>
            <table className="user-list">
                <tr>
                    <th style={{ width: '50px' }}>NO </th>
                    <th style={{ width: '300px' }}>댓글 내용</th>
                    <th style={{ width: '80px' }}>story no</th>
                    <th style={{ width: '100px' }}>작성일</th>
                    <th style={{ width: '100px' }}>작성자</th>
                    <th style={{ width: '150px' }}>메일</th>
                    <th style={{ width: '50px' }}>삭제</th>
                </tr>
                {replyList.list.length &&
                    replyList.list.map((reply: any) => {
                        return (
                            <tr key={reply.reply_no}>
                                <td>{reply.reply_no}</td>
                                <td className="left">{reply.content}</td>
                                <td>
                                    {reply.addr_key !== '' &&
                                    reply.view === 'y' &&
                                    reply.deleted === 'n' ? (
                                        <a
                                            href={`https://tosomebody.com/${reply.addr_key}`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {reply.story_no}
                                        </a>
                                    ) : (
                                        <span className="gray">
                                            {reply.story_no}
                                            {reply.view === 'n' && (
                                                <> (글 비공개)</>
                                            )}
                                            {reply.deleted === 'y' && (
                                                <> (글 삭제)</>
                                            )}
                                        </span>
                                    )}
                                </td>
                                <td>{reply.reg_date}</td>
                                <td>{reply.user_name}</td>
                                <td>{reply.user_email}</td>
                                <td>
                                    <strong>
                                        <button
                                            onClick={e =>
                                                replyDelete(
                                                    reply.reply_no,
                                                    reply.story_no,
                                                )
                                            }
                                            style={{
                                                color: 'black',
                                                backgroundColor: 'skyblue',
                                                width: 100,
                                                height: 20,
                                                borderRadius: 10,
                                            }}
                                        >
                                            삭제
                                        </button>
                                    </strong>
                                </td>
                            </tr>
                        );
                    })}
            </table>
            <table className="user-list-bottom">
                <tr>
                    <td align="left">
                        <select onChange={setPerPage} value={numPerPage}>
                            {setPerPageList.map((num, idx) => {
                                return (
                                    <option key={idx} value={num}>
                                        {num}
                                    </option>
                                );
                            })}
                        </select>
                        개씩 보기, &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <input
                            type="number"
                            id="idx_go_page"
                            defaultValue={1}
                        />{' '}
                        페이지로
                        <button onClick={goDirectPage}>이동하기</button>
                    </td>
                    <td align="right">
                        <div
                            className="page-nav-btn"
                            onClick={() => {
                                movePage(1);
                            }}
                        >
                            처음 (1)
                        </div>
                        {currPage > 1 && (
                            <div
                                className="page-nav-btn"
                                onClick={() => {
                                    movePage(currPage - 1);
                                }}
                            >
                                이전
                            </div>
                        )}

                        <div className="page-nav-btn page-nav-curr">
                            {currPage}
                        </div>

                        {nextPage > 0 && (
                            <div
                                className="page-nav-btn"
                                onClick={() => {
                                    movePage(nextPage);
                                }}
                            >
                                다음
                            </div>
                        )}
                    </td>
                </tr>
            </table>
        </div>
    );
});

export default ReportReplyList;
