import { observer } from 'mobx-react';
import store from '../../store';
import { useEffect } from 'react';
import { logout } from '../../api/ApiService';

const LeftMenu = observer(() => {
    const { adminInfo } = store;

    useEffect(() => {
        console.log(adminInfo.getLeftMenu());
        adminInfo.setLeftMenu('story');
    }, []);

    const goLeftMenu = (menu: string) => {
        adminInfo.setLeftMenu(menu);
    };

    const Logout = () => {
        logout();
        window.location.reload();
    };

    return (
        <>
            <div>
                <div
                    className={`left-menu-item ${adminInfo.getLeftMenu() === 'story' ? 'active' : ''}`}
                    onClick={() => goLeftMenu('story')}
                >
                    story 조회/수정
                </div>
                <div
                    className={`left-menu-item ${adminInfo.getLeftMenu() === 'new_story' ? 'active' : ''}`}
                    onClick={() => goLeftMenu('new_story')}
                >
                    story 등록
                </div>
                <div
                    className={`left-menu-item ${adminInfo.getLeftMenu() === 'reply_list' ? 'active' : ''}`}
                    onClick={() => goLeftMenu('reply_list')}
                >
                    전체 댓글 조회
                </div>
                <div
                    className={`left-menu-item ${adminInfo.getLeftMenu() === 'report_reply' ? 'active' : ''}`}
                    onClick={() => goLeftMenu('report_reply')}
                >
                    신고된 댓글 조회
                </div>
                <div
                    className={`left-menu-item ${adminInfo.getLeftMenu() === 'user_list' ? 'active' : ''}`}
                    onClick={() => goLeftMenu('user_list')}
                >
                    회원 목록
                </div>
                <div
                    className={`left-menu-item ${adminInfo.getLeftMenu() === 'black_list' ? 'active' : ''}`}
                    onClick={() => goLeftMenu('black_list')}
                >
                    블릭리스트 관리
                </div>
                <div
                    className={`left-menu-item ${adminInfo.getLeftMenu() === 'notice' ? 'active' : ''}`}
                    onClick={() => goLeftMenu('notice')}
                >
                    공지 사항
                </div>
                <div
                    className={`left-menu-item ${adminInfo.getLeftMenu() === 'qna' ? 'active' : ''}`}
                    onClick={() => goLeftMenu('qna')}
                >
                    이용 문의 / 건의
                </div>
                <div
                    className={`left-menu-item ${adminInfo.getLeftMenu() === 'adsr_list' ? 'active' : ''}`}
                    onClick={() => goLeftMenu('adsr_list')}
                >
                    광고주 목록
                </div>
                <div
                    className={`left-menu-item ${adminInfo.getLeftMenu() === 'ads_list' ? 'active' : ''}`}
                    onClick={() => goLeftMenu('ads_list')}
                >
                    광고 목록
                </div>
                <div
                    className={`left-menu-item ${adminInfo.getLeftMenu() === 'server_schedule_job' ? 'active' : ''}`}
                    onClick={() => goLeftMenu('server_schedule_job')}
                >
                    server schedule job
                </div>
            </div>
            <div>
                <div className={`left-menu-item logout`} onClick={Logout}>
                    Logout
                </div>
            </div>
        </>
    );
});

export default LeftMenu;
