import { observer } from 'mobx-react';
import store from '../../store';
import React, { useEffect, useState } from 'react';
import * as utils from '../../utils/utils';
import * as ApiService from '../../api/ApiService';
import '../../res/scss/user-list.scss';
import { Iuser, IusersPage } from '../../const/types';
import { set } from 'mobx';
import { send } from 'process';

const BlackList = observer(() => {
    const { adminInfo } = store;

    const [userList, setUserList] = useState([] as any[]);
    const [currPage, setCurrPage] = useState(1);
    const [nextPage, setNextPage] = useState(1);
    const [totalCnt, setTotalCnt] = useState(0);
    const setPerPageList = [10, 20, 30, 40, 50, 100];
    const [numPerPage, setNumPerPage] = useState(setPerPageList[0]);
    const [checkAll, setCheckAll] = useState(false);
    const [checkedUserEmailList, setCheckedUserEmailList] = useState<string[]>(
        [],
    );

    useEffect(() => {
        getBlackList();
    }, []);

    const getBlackList = async (page = 1, perPage = numPerPage) => {
        const dataInfo = await ApiService.getBlackListAdmin(page, perPage);
        if (dataInfo.list.length > 0) {
            console.log(dataInfo.list);
            setUserList(dataInfo.list);
        }
        setTotalCnt(dataInfo.totalCnt);
        setCheckAll(false);
    };

    const checkAllUsers = (e: any) => {
        const checked = e.target.checked;
        setCheckAll(checked);
        const userCheck = document.querySelectorAll(
            'table.user-list td input[type="checkbox"]',
        );

        const userList = [...checkedUserEmailList];
        userCheck.forEach((checkObj: any) => {
            checkObj.checked = checked;
            if (checked) {
                userList.push(checkObj.value);
            } else {
                const idx = userList.indexOf(checkObj.value);
                if (idx > -1) {
                    userList.splice(idx, 1);
                }
            }
        });

        // uniq
        const userListSet = new Set(userList);
        setCheckedUserEmailList(Array.from(userListSet));
    };

    const checkUser = (e: any) => {
        console.log('checkUser');

        const currTrObj = e.currentTarget as HTMLTableRowElement;
        if (currTrObj == null) return;
        console.dir(currTrObj);

        const firstTdObj = currTrObj.firstElementChild as HTMLTableCellElement;
        if (firstTdObj == null) return;
        console.dir(currTrObj);

        const checkObj = firstTdObj.firstElementChild as HTMLInputElement;
        if (checkObj == null) return;
        console.dir(currTrObj);

        const bCheckAct = !checkObj.checked;
        checkObj.checked = bCheckAct;

        console.log(checkObj.value);

        if (bCheckAct) {
            addCheckedUser(checkObj.value);

            const message = userList.find(
                (user: Iuser) => user.user_email === checkObj.value,
            ).message as string | '';

            setInputForm(checkObj.value, message);
        } else {
            removeCheckedUser(checkObj.value);
            setInputForm('', '');
        }

        const chkedList = document.querySelectorAll(
            'table.user-list td input[type="checkbox"]:checked',
        );
        if (chkedList.length === 0) {
            setInputForm('', '');
        }
    };

    const setInputForm = (email: string, message: string) => {
        //idx_add_user_email
        const addUserEmailObj = document.getElementById(
            'idx_add_user_email',
        ) as HTMLInputElement;
        addUserEmailObj.value = email;

        //idx_blacklist_msg
        const blacklistMsgObj = document.getElementById(
            'idx_blacklist_msg',
        ) as HTMLTextAreaElement;
        blacklistMsgObj.value = message;
    };

    const addCheckedUser = (userEmail: string) => {
        const userList = [...checkedUserEmailList];
        userList.push(userEmail);

        // uniq
        const userListSet = new Set(userList);
        setCheckedUserEmailList(Array.from(userListSet));
    };

    const removeCheckedUser = (userEmail: string) => {
        const userList = [...checkedUserEmailList];
        const idx = userList.indexOf(userEmail);
        if (idx > -1) {
            userList.splice(idx, 1);
        }
        setCheckedUserEmailList(userList);
    };

    const cancelBubble = (e: any) => {
        e.stopPropagation();
    };

    const addUser = async () => {
        const addUserEmailObj = document.getElementById(
            'idx_add_user_email',
        ) as HTMLInputElement;
        const addUserEmail = addUserEmailObj.value;
        if (addUserEmail === '') {
            alert('이메일을 입력해주세요.');
            return;
        }

        const blacklistMsgObj = document.getElementById(
            'idx_blacklist_msg',
        ) as HTMLTextAreaElement;
        const blacklistMsg = blacklistMsgObj.value;
        if (blacklistMsg === '') {
            alert('사유를 입력해주세요.');
            return;
        }
        const jsonData = {
            user_email: addUserEmail,
            msg: blacklistMsg,
        };
        const result = await ApiService.addBlackList(jsonData);

        if (result) {
            utils.showToastMessage('블랙리스트 추가에 성공하였습니다.', 1);
            getBlackList();

            // 초기화
            addUserEmailObj.value = '';
            blacklistMsgObj.value = '';
        } else {
            utils.showToastMessage('블랙리스트 추가에 실패하였습니다.', 1);
        }
    };

    const removeFromList = async () => {
        // const chkbox = document.querySelectorAll(
        //     'table.user-list td input[type="checkbox"]',
        // );
        // const checkedUserEmailList = Array.from(chkbox)
        //     .filter((obj: any) => obj.checked)
        //     .map((obj: any) => obj.value);

        if (checkedUserEmailList.length === 0) {
            alert('선택된 사용자가 없습니다.');
            return;
        }

        console.log(checkedUserEmailList);

        const jsonData = {
            blackUserEmailList: checkedUserEmailList.join(','),
        };
        console.log(jsonData);

        const result = await ApiService.removeBlackList(jsonData);

        if (result) {
            utils.showToastMessage('블랙리스트에서 제거되었습니다.', 1);
            getBlackList();
            setCheckedUserEmailList([]);
        } else {
            utils.showToastMessage('블랙리스트 제거에 실패하였습니다.', 1);
        }
    };

    return (
        <div className="body-content">
            <table className="user-list">
                <colgroup>
                    <col width="50" />
                    {/* <col width="80" /> */}
                    <col width="*" />
                    <col width="250" />
                    <col width="150" />
                </colgroup>

                <tr>
                    <th>
                        <input
                            type="checkbox"
                            checked={checkAll}
                            onChange={checkAllUsers}
                        />
                    </th>
                    {/* <th>NO</th> */}
                    <th>이메일</th>
                    <th>닉네임</th>
                    <th>사유</th>
                    <th>등록일</th>
                </tr>
                {userList.map((user, index: number) => {
                    return (
                        <tr
                            key={user.user_email}
                            onClick={checkUser}
                            className={
                                checkedUserEmailList.includes(user.user_email)
                                    ? 'selected'
                                    : ''
                            }
                        >
                            <td>
                                <input
                                    type="checkbox"
                                    value={user.user_email}
                                    onClick={cancelBubble}
                                    checked={checkedUserEmailList.includes(
                                        user.user_email,
                                    )}
                                    onChange={() => {
                                        if (
                                            checkedUserEmailList.includes(
                                                user.user_email,
                                            )
                                        ) {
                                            removeCheckedUser(user.user_email);
                                        } else {
                                            addCheckedUser(user.user_email);
                                        }
                                    }}
                                />
                            </td>
                            {/* <td>{index + 1}</td> */}
                            <td>{user.user_email}</td>
                            <td>{user.user_name || '-'}</td>
                            <td>{user.message}</td>
                            <td>{user.reg_date}</td>
                        </tr>
                    );
                })}
            </table>

            <table className="user-list-bottom">
                <tr>
                    <td className="left">left</td>
                    <td className="right">
                        <button onClick={removeFromList}>
                            리스트에서 제거
                        </button>
                    </td>
                </tr>
            </table>

            <div className="push-noti">
                <div className="line title">사용자 추가</div>

                <div className="line">
                    <div className="head">이메일주소</div>
                    <div className="body">
                        <input
                            type="text"
                            id="idx_add_user_email"
                            className="subject"
                        />
                    </div>
                </div>
                <div className="line">
                    <div className="head">사유</div>
                    <div className="body">
                        <textarea id="idx_blacklist_msg" />
                    </div>
                </div>
                <div className="line">
                    <div className="head"></div>
                    <div className="body">
                        {checkedUserEmailList.length > 0 ? (
                            <button onClick={addUser}>수정</button>
                        ) : (
                            <button onClick={addUser}>등록</button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
});

export default BlackList;
