import { observer } from 'mobx-react';
import store from '../../store';
import React, { useEffect, useState } from 'react';
import * as utils from '../../utils/utils';
import * as ApiService from '../../api/ApiService';
import '../../res/scss/user-list.scss';
import { Iuser, IusersPage } from '../../const/types';
import { set } from 'mobx';
import { send } from 'process';

const UserList = observer(() => {
    const { adminInfo } = store;

    const [userList, setUserList] = useState([] as Iuser[]);
    const [currPage, setCurrPage] = useState(1);
    const [nextPage, setNextPage] = useState(1);
    const [totalCnt, setTotalCnt] = useState(0);
    const setPerPageList = [10, 20, 30, 40, 50, 100];
    const [numPerPage, setNumPerPage] = useState(setPerPageList[0]);
    const [checkAll, setCheckAll] = useState(false);
    const [checkedUserNoList, setCheckedUserNoList] = useState<number[]>([]);

    useEffect(() => {
        getUserList();
    }, []);

    const getUserList = async (page = 1, perPage = numPerPage) => {
        const searchWordForm = document.getElementById(
            'idx_search_name',
        ) as HTMLInputElement;
        const searchWord = searchWordForm.value.trim();

        const dataInfo: IusersPage = await ApiService.getUserList(
            page,
            perPage,
            searchWord,
        );
        if (dataInfo.list.length > 0) {
            console.log(dataInfo.list);
            setUserList(dataInfo.list);
        }
        setCurrPage(dataInfo.currentPage);
        setTotalCnt(dataInfo.totalCnt);
        setNextPage(dataInfo.nextPage);

        setCheckAll(false);
    };

    const movePage = (page: number) => {
        getUserList(page);
    };

    const setPerPage = (e: any) => {
        const perPage = e.target.value;
        setNumPerPage(perPage);
        getUserList(1, perPage);
    };

    const goDirectPage = () => {
        const idx = document.getElementById('idx_go_page') as HTMLInputElement;
        const page = parseInt(idx.value);
        if (page > 0 && page <= Math.ceil(totalCnt / numPerPage)) {
            getUserList(page);
        } else {
            utils.showToastMessage(
                '잘못된 페이지 번호입니다.\n마지막 페이지는 ' +
                    Math.ceil(totalCnt / numPerPage) +
                    ' 페이지 입니다.',
                2,
            );
            idx.value = '1';
        }
    };

    const checkAllUsers = (e: any) => {
        const checked = e.target.checked;
        setCheckAll(checked);
        const userCheck = document.querySelectorAll(
            'table.user-list td input[type="checkbox"]',
        );

        const userNoList = [...checkedUserNoList];
        userCheck.forEach((checkObj: any) => {
            checkObj.checked = checked;
            if (checked) {
                userNoList.push(parseInt(checkObj.value));
            } else {
                const idx = userNoList.indexOf(parseInt(checkObj.value));
                if (idx > -1) {
                    userNoList.splice(idx, 1);
                }
            }
        });

        // uniq
        const userNoSet = new Set(userNoList);
        setCheckedUserNoList(Array.from(userNoSet));
    };

    const checkUser = (e: any) => {
        console.log('checkUser');

        const currTrObj = e.currentTarget as HTMLTableRowElement;
        if (currTrObj == null) return;
        console.dir(currTrObj);

        const firstTdObj = currTrObj.firstElementChild as HTMLTableCellElement;
        if (firstTdObj == null) return;
        console.dir(currTrObj);

        const checkObj = firstTdObj.firstElementChild as HTMLInputElement;
        if (checkObj == null) return;
        console.dir(currTrObj);

        const bCheckAct = !checkObj.checked;
        checkObj.checked = bCheckAct;

        if (bCheckAct) {
            addCheckedUser(parseInt(checkObj.value));
        } else {
            removeCheckedUser(parseInt(checkObj.value));
        }
    };

    const addCheckedUser = (userNo: number) => {
        const userNoList = [...checkedUserNoList];
        userNoList.push(userNo);

        // uniq
        const userNoSet = new Set(userNoList);
        setCheckedUserNoList(Array.from(userNoSet));
    };

    const removeCheckedUser = (userNo: number) => {
        const userNoList = [...checkedUserNoList];
        const idx = userNoList.indexOf(userNo);
        if (idx > -1) {
            userNoList.splice(idx, 1);
        }
        setCheckedUserNoList(userNoList);
    };

    const cancelBubble = (e: any) => {
        e.stopPropagation();
    };

    const sendFCM = async () => {
        const pushTarget = document.querySelector(
            'input[name="push_target"]:checked',
        ) as HTMLInputElement;
        const pushTitle = document.getElementById(
            'idx_push_title',
        ) as HTMLInputElement;
        const pushMsg = document.getElementById(
            'idx_push_msg',
        ) as HTMLTextAreaElement;
        const pushMenu = document.getElementById(
            'idx_push_menu',
        ) as HTMLSelectElement;
        const pushParam = document.getElementById(
            'idx_push_param',
        ) as HTMLInputElement;

        const pushTargetValue = pushTarget.value;
        const title = pushTitle.value.trim();
        const msg = pushMsg.value.trim();
        const menu = pushMenu.value.trim();
        let param = pushParam.value.trim();

        if (title === '') {
            utils.showToastMessage('제목을 입력해 주세요.', 1);
            return;
        }
        if (msg === '') {
            utils.showToastMessage('내용을 입력해 주세요.', 1);
            return;
        }

        const userCheck = document.querySelectorAll(
            'table.user-list td input[type="checkbox"]',
        );
        const userNoList: number[] = [...checkedUserNoList];

        if (pushTargetValue === 'select' && userNoList.length === 0) {
            utils.showToastMessage('선택된 사용자가 없습니다.', 1);
            return;
        }

        if (menu === 'home') {
        }
        if (menu === 'notice') {
            if (param === '') {
                utils.showToastMessage('파라미터를 입력해 주세요.', 1);
                return;
            }
            // 숫자만 가능
            if (isNaN(parseInt(param))) {
                utils.showToastMessage(
                    '공지사항 번호를 숫자로 입력해 주세요.',
                    1,
                );
                return;
            }
        }
        if (menu === 'detail') {
            if (param === '') {
                utils.showToastMessage(
                    '파라미터란에 스토리 주소를 입력해 주세요.',
                    1,
                );
                return;
            }
            if (utils.checkStoryId(param) === false) {
                utils.showToastMessage('스토리 주소가 올바르지 않습니다.', 1);
                return;
            }
        }
        if (menu === 'home' || menu === 'mypage') {
            param = '';
        }

        let confirmMsg = '푸시를 발송한 이후에는 취소할 수 없습니다.\n';
        if (pushTargetValue === 'select') {
            confirmMsg += `선택된 ${userNoList.length}명의 사용자에게 푸시를 전송하시겠습니까?`;
        } else if (pushTargetValue === 'allow_push') {
            confirmMsg +=
                '[전체] 수신동의한 사용자 모두 에게 푸시를 전송하시겠습니까?';
        } else if (pushTargetValue === 'all') {
            confirmMsg +=
                '[전체] 수신동의 관계없이 모든 사용자에게 푸시를 전송하시겠습니까?';
        } else {
            alert('대상을 선택해 주세요.');
            return;
        }

        if (confirm(confirmMsg) === false) {
            return;
        }

        const sendData = {
            toType: pushTargetValue,
            toUserNoListString: userNoList.join(','),
            title: title,
            message: msg,
            menu: menu,
            param: param,
        };

        const resultCnt = await ApiService.sendPushMessage(sendData);

        if (resultCnt > 0) {
            // alert(`푸시 전송 성공 ${resultCnt}명에게 전송`);
            utils.showToastMessage('푸시 전송 성공', 2);
        } else {
            // alert('푸시 전송 실패');
            utils.showToastMessage('푸시 전송 실패', 2);
        }
    };

    const changePushTarget = (e: any) => {
        const target = e.currentTarget.value;
        const selectedUserContainer = document.getElementById(
            'idx_selected_user_container',
        ) as HTMLDivElement;
        if (target === 'select') {
            selectedUserContainer.style.display = '';
        } else {
            selectedUserContainer.style.display = 'none';
        }
    };

    const searchUser = () => {
        getUserList();
    };

    const showErrorImage = (e: any) => {
        //e.target.src = '/images/no-image.png';
        const imageObj = e.currentTarget as HTMLImageElement;
        imageObj.parentElement?.removeChild(imageObj);
    };

    // const encrease = (e: any) => {
    //     const imageObj = e.currentTarget as HTMLImageElement;
    //     imageObj.style.width = '200px';
    // };
    // const decrease = (e: any) => {
    //     const imageObj = e.currentTarget as HTMLImageElement;
    //     imageObj.style.width = '60px';
    // };

    return (
        <div className="body-content">
            <table className="user-list-top">
                <tr>
                    <td className="left">
                        <select onChange={setPerPage} value={numPerPage}>
                            {setPerPageList.map((num, idx) => {
                                return (
                                    <option key={idx} value={num}>
                                        {num}
                                    </option>
                                );
                            })}
                        </select>
                        개씩 보기, &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <input
                            type="number"
                            id="idx_go_page"
                            defaultValue={1}
                        />{' '}
                        페이지로
                        <button onClick={goDirectPage}>이동하기</button>
                    </td>
                    <td>
                        <div className="search-form">
                            <input
                                type="text"
                                id="idx_search_name"
                                placeholder="이름(메일) 검색"
                                onKeyDown={e => {
                                    if (e.key === 'Enter') {
                                        searchUser();
                                    }
                                }}
                            />
                            <button onClick={searchUser}>검색</button>
                        </div>
                    </td>
                    <td>
                        <div className="">
                            전체 <b>{totalCnt}</b> 명
                        </div>
                    </td>
                </tr>
            </table>
            <table className="user-list">
                <colgroup>
                    <col width="20" />
                    <col width="50" />
                    <col width="66" />
                    <col width="170" />
                    <col width="*" />
                    <col width="150" />
                    <col width="150" />
                    <col width="70" />
                    <col width="50" />
                    <col width="150" />
                    <col width="70" />
                    <col width="70" />
                </colgroup>

                <tr>
                    <th>
                        <input
                            type="checkbox"
                            checked={checkAll}
                            onChange={checkAllUsers}
                        />
                    </th>
                    <th>NO</th>
                    <th colSpan={2}>닉네임</th>
                    <th>이메일</th>
                    <th>가입일</th>
                    <th>수정일</th>
                    <th>가입경로</th>
                    <th>알림</th>
                    <th>마지막 로그인</th>
                    <th>상태</th>
                    <th>차단</th>
                </tr>
                {userList.map(user => {
                    return (
                        <tr
                            key={user.user_no}
                            onClick={checkUser}
                            className={
                                checkedUserNoList.includes(user.user_no)
                                    ? 'selected'
                                    : ''
                            }
                        >
                            <td>
                                <input
                                    type="checkbox"
                                    value={user.user_no}
                                    onClick={cancelBubble}
                                    checked={checkedUserNoList.includes(
                                        user.user_no,
                                    )}
                                    onChange={() => {
                                        if (
                                            checkedUserNoList.includes(
                                                user.user_no,
                                            )
                                        ) {
                                            removeCheckedUser(user.user_no);
                                        } else {
                                            addCheckedUser(user.user_no);
                                        }
                                    }}
                                />
                            </td>
                            <td>{user.user_no}</td>
                            <td className="thumb">
                                {user.user_image !== '' && (
                                    <img
                                        src={user.user_image}
                                        alt=""
                                        onError={showErrorImage}
                                        // onMouseOver={encrease}
                                        // onMouseOut={decrease}
                                    />
                                )}
                            </td>
                            <td>{user.user_name}</td>
                            <td>{user.user_email}</td>
                            <td>{user.reg_date}</td>
                            <td>{user.mod_date}</td>
                            <td>{user.reg_from}</td>
                            <td>{user.noti_yn}</td>
                            <td>{user.last_login}</td>
                            <td>
                                {user.user_status == 1 && (
                                    <span className="normal">정상</span>
                                )}
                                {user.user_status == -1 && (
                                    <span className="red">글쓰기금지</span>
                                )}
                                {user.user_status == -5 && (
                                    <span className="black">black</span>
                                )}
                                {user.user_status == 9 && (
                                    <span className="admin">관리자</span>
                                )}
                            </td>

                            <td>
                                <button
                                    style={{
                                        color: 'black',
                                        backgroundColor: 'skyblue',
                                        width: 50,
                                        height: 20,
                                        borderRadius: 10,
                                    }}
                                >
                                    차단
                                </button>
                            </td>
                        </tr>
                    );
                })}
            </table>
            <table className="user-list-bottom">
                <tr>
                    <td className="left">
                        {/* <select onChange={setPerPage} value={numPerPage}>
                            {setPerPageList.map((num, idx) => {
                                return (
                                    <option key={idx} value={num}>
                                        {num}
                                    </option>
                                );
                            })}
                        </select>
                        개씩 보기, &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <input
                            type="number"
                            id="idx_go_page"
                            defaultValue={1}
                        />{' '}
                        페이지로
                        <button onClick={goDirectPage}>이동하기</button> */}
                    </td>
                    <td align="right">
                        <div
                            className="page-nav-btn"
                            onClick={() => {
                                movePage(1);
                            }}
                        >
                            처음 (1)
                        </div>
                        {currPage > 1 && (
                            <div
                                className="page-nav-btn"
                                onClick={() => {
                                    movePage(currPage - 1);
                                }}
                            >
                                이전
                            </div>
                        )}

                        <div className="page-nav-btn page-nav-curr">
                            {currPage}
                        </div>

                        {nextPage > 0 && (
                            <div
                                className="page-nav-btn"
                                onClick={() => {
                                    movePage(nextPage);
                                }}
                            >
                                다음
                            </div>
                        )}
                        <div
                            className="page-nav-btn"
                            onClick={() => {
                                movePage(Math.ceil(totalCnt / numPerPage));
                            }}
                        >
                            마지막 ({Math.ceil(totalCnt / numPerPage)})
                        </div>
                    </td>
                </tr>
            </table>

            <div className="push-noti">
                <div className="line title">푸시 알림</div>
                <div className="line">
                    <div className="head">대상</div>
                    <div className="body">
                        <input
                            type="radio"
                            name="push_target"
                            id="idx_push_target_select"
                            value="select"
                            defaultChecked
                            onChange={changePushTarget}
                        />
                        <label htmlFor="idx_push_target_select">
                            체크된 사용자 ( {checkedUserNoList.length}명 )
                        </label>

                        <input
                            type="radio"
                            name="push_target"
                            id="idx_push_target_agree"
                            value="allow_push"
                            onChange={changePushTarget}
                        />
                        <label htmlFor="idx_push_target_agree">
                            수신동의한 사용자 전체
                        </label>

                        <input
                            type="radio"
                            name="push_target"
                            id="idx_push_target_all"
                            value="all"
                            onChange={changePushTarget}
                        />
                        <label htmlFor="idx_push_target_all">
                            전체(수신동의 관계없음)
                        </label>
                    </div>
                </div>
                <div className="line" id="idx_selected_user_container">
                    <div className="head">수신자</div>
                    <div className="body">
                        {checkedUserNoList.length === 0 ? (
                            '선택된 사용자 없음'
                        ) : (
                            <>
                                {/* <span className="blue-font-color">
                                    {checkedUserNoList.length}명 :
                                </span> */}
                                <span className="grey-font-color">
                                    {checkedUserNoList.join(', ')}
                                </span>
                            </>
                        )}

                        {/* // checkedUserNoList.length +
                            //   '명 : ' +
                            //   checkedUserNoList.join(', ')} */}
                    </div>
                </div>
                <div className="line">
                    <div className="head">제목</div>
                    <div className="body">
                        <input
                            type="text"
                            id="idx_push_title"
                            className="subject"
                        />
                    </div>
                </div>
                <div className="line">
                    <div className="head">내용</div>
                    <div className="body">
                        <textarea id="idx_push_msg" />
                    </div>
                </div>
                <div className="line">
                    <div className="head">이동위치</div>
                    <div className="body">
                        <select id="idx_push_menu">
                            <option value="">이동 안함</option>
                            <option value="home">홈</option>
                            <option value="notice">공지사항</option>
                            <option value="detail">스토리 보기</option>
                            <option value="mypage">마이페이지</option>
                        </select>
                    </div>
                </div>
                <div className="line">
                    <div className="head">파라미터</div>
                    <div className="body col">
                        <input
                            type="text"
                            id="idx_push_param"
                            className="param"
                            autoComplete="off"
                        />
                        <p>공지사항 : 공지번호, 스토리: 스토리주소</p>
                    </div>
                </div>
                <div className="line">
                    <div className="head"></div>
                    <div className="body">
                        <button onClick={sendFCM}>전송</button>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default UserList;
